import React, { useRef, useEffect } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot, ReferenceLine
} from 'recharts';
import './styles.css';
import { getDDDMMDD, getTime12hrsData, convertDate } from '../../utils/dateHelper';
import moment from "moment"
import { BorderColor } from '@mui/icons-material';
import { Blocks, TailSpin } from 'react-loader-spinner';

const BloodPressureGraph = ({ data, usePdf, isMonitoringAssigned,loading,readingCycle }) => {
  const bloodPressureGraphRef = useRef(null);

  const calculateBarchartWidth = (numberOfItem = 7) => { if (numberOfItem > 6) return numberOfItem * 75; return '100%'; };


  const CustomDot = ({ cx, cy, fill, payload, dataKey }) => {
    let dotColor = fill;

    if (dataKey === 'diastolic') {
      dotColor = payload.isAbnormalDiastolic ? 'red' : '#E2B71F';
    } else if (dataKey === 'systolic') {
      dotColor = payload.isAbnormalSystolic ? 'red' : '#89C9F8';
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={dotColor}
        stroke="none"
        style={{ pointerEvents: 'none' }}
      />
    );
  };

  useEffect(() => {
    if (bloodPressureGraphRef.current) {
      bloodPressureGraphRef.current.scrollLeft = bloodPressureGraphRef.current.scrollWidth;
    }
  }, [data]);

  const formattedData = (data)?.map(entry => ({
    date: getDDDMMDD(entry.date),
    systolic: parseInt(entry.systolic || 0),
    diastolic: parseInt(entry.diastolic || 0),
    // systolic: entry.systolic,
    // diastolic: entry.diastolic,
    isAbnormalDiastolic: entry.isAbnormalDiastolic,
    isAbnormalSystolic: entry.isAbnormalSystolic,
    systolicMin: entry.systolicMin,
    systolicMax: entry.systolicMax,
    diastolicMin: entry.diastolicMin,
    diastolicMax: entry.diastolicMax
  })) || [];

  // console.log(data, "blood pressure data")

  const systolicMin = Math.min(...formattedData.map(entry => entry.systolicMin));
  const systolicMax = Math.min(...formattedData.map(entry => entry.systolicMax));
  const diastolicMin = Math.min(...formattedData.map(entry => entry.diastolicMin));
  const diastolicMax = Math.min(...formattedData.map(entry => entry.diastolicMax));

  const yMin = Math.min(systolicMin, diastolicMin);
  const yMax = Math.max(systolicMax, diastolicMax);

  // console.log("YAxis domain:", {
  //   yMin: Math.min(yMin, diastolicMax),
  //   yMax: Math.max(yMax, diastolicMax),
  // });
  // console.log("Reference line value:", diastolicMax);
  const dummyTableData = (data)?.map(entry => ({
    dateTime: `${moment(entry?.date).format('MM/DD/YYYY hh:mm A')}`,
    device: entry.device,
    range: `${entry.range} ${entry.unit}`
  })) || [];


  if (!isMonitoringAssigned) {
    return null;
  }

  const CustomLegend = ({ data }) => {
    return (
      <div style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20 }}>
        {data.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
            <div
              style={{
                width: 26,
                height: 26,
                borderRadius: '50%',
                backgroundColor: item.color,
                marginRight: 10,
              }}
            ></div>
            <span style={{ color: '#000', fontSize: 14 }}>{item.name}</span>
          </div>
        ))}
      </div>
    );
  };
  const legendData = [
    { name: 'Systolic', color: '#89C9F8' },
    { name: 'Diastolic', color: '#E2B71F' },
  ];
  function getDomainValues(data) {
    const systolicValues = data.map((item) => parseInt(item.systolic, 10));
    const diastolicValues = data.map((item) => parseInt(item.diastolic, 10));
  
    const combinedValues = [...systolicValues, ...diastolicValues];
    const maxCombined = Math.max(...combinedValues);
  
    // Round up to the nearest 10
    const roundedMax = Math.ceil(maxCombined / 10) * 10;
    return [0, roundedMax];
  }
  function getTicks(data) {
    const domain = getDomainValues(data); // Get [0, roundedMax]
    const max = domain[1]; // Use roundedMax from domain
    const step = max / 5; // Divide into 5 intervals (adjust as needed)
  
    // Generate tick values
    return Array.from({ length: 6 }, (_, i) => i * step);
  }

  return (
    <div className="graph-container">
      {!usePdf &&
      <>
       <h2 className="graphHeading">Blood Pressure</h2>
       <div className='pt-2'>
       <p className='graphCycle'>Current cycle: {convertDate(readingCycle?.cycleStart)} - {convertDate(readingCycle?.cycleEnd)} </p>
           <p className='graphCycle'>Days of readings within current cycle: {readingCycle?.readingCount}</p>
         </div>
       </>
       }
     

      {loading && 
             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: '50px'}}>
             <TailSpin
               visible={true}
               height="80"
               width="80"
               color="#03A84E"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
             />
               </div>
            }
      {(data && data.length && !loading ) ? (
        <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <CustomLegend data={legendData} />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '65px' }}>
              <ResponsiveContainer width={'100%'} height={275} >
                <LineChart data={formattedData?.reverse()} style={{ border: 2, BorderColor: 'red' }}>
                  <YAxis
                    dy={0}
                    type="number"
                    dataKey='diastolic'
                    domain={getDomainValues(data)}
                    axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                    tick={{ fill: '#000', fontSize: 12 }}
                    ticks={getTicks(data)}
                    padding={{ right: 20 }}
                    tickLine={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }} ref={bloodPressureGraphRef}>
              <ResponsiveContainer width={calculateBarchartWidth(formattedData.length)} height={300}>
                <LineChart data={formattedData} >
                  <XAxis
                    dataKey="date"
                    angle={0}
                    dx={-30}
                    minTickGap={-200}
                    textAnchor="start"
                    tick={{ fill: '#000', fontSize: 12 }}
                    tickLine={false}
                    axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                    padding={{left:30, right: 20 }}
                    style={{ backgroundColor: 'red' }}
                  />
                  <Tooltip />
                  {/* <Legend
                verticalAlign="top"
                align="right"
                iconType="circle"
                iconSize={26}
                wrapperStyle={{ paddingBottom: 20, paddingLeft: 20 }}
                formatter={(value, entry) => (
                  <span style={{ color: '#000', fontSize: 14 }}>
                    {value}
                  </span>
                )}
              /> */}
                  <Line
                    type="monotone"
                    dataKey="systolic"
                    stroke="#89C9F8"
                    name="Systolic"
                    strokeWidth={3}
                    // dot={(props) => <CustomDot {...props} dataKey="systolic" />}
                    dot={(props) => {
                      const { key, ...rest } = props;
                      return <CustomDot {...rest} dataKey="systolic" />;
                  }}
                  />
                  <Line
                    type="monotone"
                    dataKey="diastolic"
                    stroke="#E2B71F"
                    name="Diastolic"
                    strokeWidth={3}
                    // dot={(props) => <CustomDot {...props} dataKey="diastolic" />}
                    dot={(props) => {
                      const { key, ...rest } = props; 
                      return <CustomDot {...rest} dataKey="diastolic" />;
                  }}
                  />
                  {/* <ReferenceLine y={systolicMin} stroke="black" strokeDasharray="3 3" />
                  <ReferenceLine y={systolicMax} stroke="black" strokeDasharray="3 3" />
                  <ReferenceLine y={diastolicMin} stroke="black" strokeDasharray="3 3" />
                  <ReferenceLine y={diastolicMax} stroke="black" strokeDasharray="3 3" /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      ) : (
        <div>
          {
            !loading && <p className='d-flex justify-content-center align-items-center' style={{ minHeight: 300 }}>
             No Chart Data Found
           </p>
          }

        </div>
       
      )}


      {!usePdf && <div className='graphtable-container'>
        <table className='graphdata-table'>
          <thead className='graphTableHead'>
            <tr className='graphTableborder'>
              <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Date & Time</th>
              <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Device</th>
              <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Range</th>
            </tr>
          </thead>
        </table>

        <div className="table-body-container">
          <table className="graphdata-table">
            <tbody>
              {dummyTableData.map((entry, index) => (
                <tr key={index}>
                  <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.dateTime}</td>
                  <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.device}</td>
                  <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.range}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {dummyTableData.length == 0 && !loading && <p>
          No Data Found
        </p>}
      </div>}
    </div>
  );
};

export default BloodPressureGraph;
