import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function Conditions({
  show,
  onHide,
  isChecked,
  onCheckboxChange,
}: any) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    onCheckboxChange(isChecked);
  };

  return (
    <Modal className="baModal modal-kyc" show={show} onHide={onHide} animation={false} size="lg">
      {/* <Modal.Header
        className="header-popup"
        closeButton
        //   onHide={handleHide}
        placeholder={null}
      /> */}
      <div className="header-popup modal-header">
        <button onClick={onHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
      </div>
      <Modal.Title>
        <h6 className="assessques h5 thick-heading">
        Terms of Use
        </h6>
      </Modal.Title>

      <Modal.Body>
        <div className="text-justify">

          <p className="assessques thick-heading mt-4 text-uppercase">
            <strong>RPM by OtisHealth &ndash; Terms of Use</strong>
          </p>

          <p className="assessques thick-heading">
            <strong>Effective Date: July 16, 2024</strong>
          </p>

          <p className="graphText pb-10">
            Welcome to the Terms of use (&ldquo;
            <strong>
              <em>Terms</em>
            </strong>
            &rdquo;) for the OtisHealth, Inc. (&ldquo;
            <strong>
              <em>Company</em>
            </strong>
            &rdquo;, &ldquo;
            <strong>
              <em>we</em>
            </strong>
            &rdquo;, &ldquo;
            <strong>
              <em>our</em>
            </strong>
            &rdquo; or &ldquo;
            <strong>
              <em>us</em>
            </strong>
            &rdquo;) platform currently provided via the Company&rsquo;s mobile
            apps and online platforms (the &ldquo;
            <strong>
              <em>Company Platform</em>
            </strong>
            &rdquo;{" "}
            <strong>
              <em>or &ldquo;Platform&rdquo;</em>
            </strong>
            ), and available through web browsers and by downloading from the
            Apple App Store and the Android Play Store.&nbsp;&nbsp;&nbsp;&nbsp;
          </p>

          <p className="graphText">
            PLEASE READ THESE TERMS (INCLUDING THE PRIVACY POLICY FOR THE
            COMPANY PLATFORM, LOCATED <a href="https://otishealth.net/rpm_privacy" target="_blank">HERE</a>, WHICH IS INCORPORATED HEREIN BY
            REFERENCE) CAREFULLY AND IN THEIR ENTIRETY.&nbsp; THEY SET FORTH THE
            LEGALLY BINDING TERMS OF USE THAT GOVERN YOUR ACCESS TO AND
            USE OF THE COMPANY PLATFORM.&nbsp; BY USING THE COMPANY SERVICES,
            YOU ARE ACCEPTING THESE TERMS.&nbsp; IF YOU DO NOT AGREE WITH ALL OF
            THE PROVISIONS OF THESE TERMS, YOU ARE NOT AUTHORIZED TO ACCESS OR
            USE THE COMPANY PLATFORM.&nbsp;
          </p>

          <h1 className="assessques mt-3 thick-heading text-uppercase">The Company Platform</h1>

          <p className="graphText mt-3">
            The Company Platform, which is powered by OtisHealth, provides a health management system that allows (1) providers the ability to enroll patients, assign patients certain medical device measurement tasks, and review  and monitor a patient’s medical device readings and (2) patients the ability to connect and sync medical devices, and then track and share their medical device readings with caregivers and healthcare providers. “Providers” refers to healthcare practices and healthcare providers, including non-clinical staff members. “Patients” refers to individuals who seek or receive medical, diagnostic, therapeutic, or preventive healthcare services from licensed or authorized healthcare providers.
          </p>

          <h1 className="assessques mt-3 thick-heading text-uppercase">Your Account</h1>
          <p className="graphText mt-3">
            All providers and patients (&ldquo;
            <strong>
              <em>Users</em>
            </strong>
            &rdquo;) must create an account with us (&ldquo;
            <strong>
              <em>Account</em>
            </strong>
            &rdquo;) to access and use the Company Platform.
          </p>
          <p className="graphText ptb-10">
            As a User, you can create an Account on the Company Platform if you: (a) agree to be bound by these Terms, (b) are at least 18 years of age, and (c) submit certain information about yourself, including personally identifiable information, to us through the Company Platform as prompted during the Account registration process.  We will delete your Account if we find out that you are not at least 18 years of age.  Once you have created an Account through the Company Platform, we will provide you with a username and password for accessing your Account.  In addition to the information provided during the registration process, you can also choose to provide additional information about yourself through your Account on the Company Platform, including pictures and other personal information about yourself (
            collectively, <strong>
              <em>&ldquo;Account Information&ldquo;</em></strong>).

          </p>

          <p className="graphText">
            The Company Platform allows you to view and update your Account Information, including your email address.  You are responsible for all activity that occurs through your Account, including the purchase of products and services and your participation, if any, in activities arising from your use of the platform.  You agree to maintain the security and secrecy of your Account’s username and password.
          </p>

          <h1 className="assessques mt-3 thick-heading text-uppercase">Your Use of The Company Platform</h1>

          <p className="graphText ptb-10">
            <em>Your Licenses</em>
          </p>

          <p className="graphText pb-10">
            Subject to, and conditioned upon, your compliance with the terms of use set forth in these Terms, including the restrictions on your use of the Company Platform, we hereby grant you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to access and use the Company Platform for your personal use.&nbsp;
          </p>

          <p className="graphText">
            YOU MAY NOT USE THE COMPANY PLATFORM IN ANY WAY NOT EXPRESSLY APPROVED IN YOUR ABOVE LICENSE.&nbsp;
          </p>

          <p className="graphText ptb-10">
            <em>Confidentiality</em>
          </p>

          <p className="graphText">
            You agree to maintain as confidential any information received from
            any other user and not share, disclose or use the information other
            than as permitted by these Terms.
          </p>

          <p className="graphText ptb-10">
            <em>Restrictions on Use of the Company Platform</em>
          </p>

          <p className="graphText">
            By accessing or using the Company Platform, you agree not to use it
            to collect, upload, transmit, display or distribute any content or
            material that we determine, in our sole discretion:
          </p>

          <ul className="graphText pb-10">
            <li>
              violates any third party&rsquo;s right, including any copyright,
              trademark, patent, trade secret, moral right, privacy right, right
              of publicity or any other intellectual property or proprietary
              right;
            </li>
            <li>
              is unlawful, harassing, abusive, tortious, threatening, harmful,
              invasive of another&rsquo;s privacy, profane, vulgar, defamatory,
              false, intentionally misleading, trade libelous, pornographic,
              obscene, patently offensive, promotes racism, bigotry, hatred or
              physical harm of any kind against any group or individual or is
              otherwise objectionable;
            </li>
            <li>is harmful to minors in any way; or</li>
            <li>
              is in violation of any law, regulation, obligation or other
              similar restrictions imposed by any third party, including any
              confidentiality obligations from former employers.
            </li>
          </ul>

          <p className="graphText">Additionally, you shall not, and shall not permit others to:</p>

          <ul className="graphText pb-10">
            <li>
              use the Company Platform for any unlawful purpose or for any
              commercial activities, including without limitation collecting
              data or market research, promoting or advertising, raising money,
              marketing or otherwise using the Company Platform in a manner not
              consistent with your license and the purpose of the Company
              Platform;
            </li>
            <li>
              provide purposefully untruthful or inaccurate advice that other
              users may rely upon in a detrimental manner;
            </li>
            <li>
              mention other people by name in your advice without their prior
              consent, except for public figures and celebrities who are
              generally known to the public;
            </li>
            <li>
              impersonate any person or entity or falsely state or otherwise
              misrepresent your affiliation with a person or entity;
            </li>
            <li>
              modify, translate, adapt or otherwise create derivative works or
              improvements of the Company Platform or the content and materials
              that we make available to you;
            </li>
            <li>
              reverse engineer, disassemble, decompile, decode or otherwise
              attempt to derive or gain access to the source code of the Company
              Platform;
            </li>
            <li>
              remove, delete, alter or obscure any trademarks or any copyright,
              trademark, patent or other intellectual property or proprietary
              rights notices from or contained on the Company Platform,
              including any copy thereof;
            </li>
            <li>
              rent, lease, lend, sell, sublicense, assign, distribute, publish,
              transfer or otherwise make available the Company Platform or any
              features or functionality thereof to any third party;
            </li>
            <li>
              remove, disable, circumvent or otherwise create or implement any
              workaround to any copy protection, rights management or security
              features in or protecting the Company Platform;
            </li>
            <li>
              upload, transmit or distribute to or through the Company Platform
              any computer viruses or any software intended to damage or alter a
              computer system or data; or
            </li>
            <li>
              use software or automated agents or scripts to generate automated
              searches, requests or queries to, or strip, scrape, or mine data
              from, the Company Platform.
            </li>
          </ul>

          <p className="graphText">
            We reserve the right to delete or deactivate your Account and deny
            you access to all or part of the Company Platform if we believe that
            you have violated or will violate these Terms.
          </p>

          <p className="graphText ptb-10">
            <em>Third-Party Content </em>
          </p>

          <p className="graphText">
            The Company Platform may contain links to third-party websites and
            services (&ldquo;
            <strong>
              <em>Third-Party Links</em>
            </strong>
            &rdquo;).&nbsp; We are not responsible for any Third-Party Links,
            and we do not approve, monitor, endorse, warrant or make any
            representations with respect to Third-Party Links.&nbsp; You use all
            Third-Party Links at your own risk and should apply a suitable level
            of caution, diligence and discretion in doing so.&nbsp; When you
            click on any of the Third-Party Links, the applicable third
            party&rsquo;s terms and policies apply, including the third
            party&rsquo;s privacy and information gathering practices.&nbsp;
          </p>

          <p className="graphText ptb-10">
            <em>Your Privacy</em>
          </p>

          <p className="graphText pb-10">
            All of the information we collect through or in connection with your use of the Company Platform is subject to the <a href="https://otishealth.net/rpm_privacy" target="_blank">Company Privacy Policy</a>, which is incorporated by reference into these Terms.  In addition, Providers are subject to the <a href="https://privacy.stripe.com/" target="_blank">Stripe payment platform privacy policy</a>.  Please read the Company Privacy Policy carefully before using the Company Platform.
          </p>

          <h1 className="assessques thick-heading text-uppercase">Charges and Compensation</h1>

          <p className="graphText pb-10">For Patient users, does not charge fees for downloading, accessing or using the Company Platform. We do not ask for, receive, or store Patient user's financial information.</p>

          <p className="graphText">For Provider users, there is a one-time registration fee and a "per patient/per month" fee for patients enrolled into the Provider's RPM by OtisHealth program.</p>
          <p className="graphText ptb-10"><em>Services Provided</em></p>
          <p className="graphText">We provide products and/or services ("Services") which you can purchase using Stripe Checkout, a third-party payment processor. All transactions are subject to Stripe's terms of use.</p>
          <p className="graphText ptb-10"><em>Stripe Account Registration</em></p>
          <p className="graphText">To use our Services, you may be required to create a Stripe account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
          <p className="graphText ptb-10"><em>Payment and Billing</em></p>
          <p className="graphText pb-10">We accept payments via Stripe Checkout. By providing a payment method, you represent and warrant that you are authorized to use the designated payment method and that you authorize Stripe to charge your payment method for the total amount of your purchase (including any applicable taxes and other charges).</p>
          <p className="graphText pb-10">All billing information provided must be truthful and accurate. Providing any untruthful or inaccurate information constitutes a breach of these Terms and may result in cancellation of your order.</p>
          <p className="graphText pb-10">If there is a dispute regarding your payment, you agree to contact us directly to resolve the issue. Stripe may also handle disputes under their own policies and procedures.</p>
          <p className="graphText pb-10">All sales are final unless otherwise stated.</p>


          <h1 className="assessques thick-heading text-uppercase">Intellectual Property Rights</h1>

          <p className="graphText pb-10">
            <em>Our Content</em>
          </p>

          <p className="graphText pb-10">
            Subject only to the express licenses we grant you in these Terms, as
            between you and us, we own all right, title and interest in all
            original content, features and functionality found on, provided by
            or embodied in the Company Platform, including without limitation
            any software, computer code (including source code or object code),
            copyright-protected works, trademarks, trade dress, service marks,
            logos, visual interfaces, graphics, design, compilation,
            information, data, sound files, other files and the selection and
            arrangement thereof (&ldquo;
            <strong>
              <em>Our Content</em>
            </strong>
            &rdquo;).&nbsp; Our Content is protected by copyright, trademark,
            and other intellectual property and related laws of both the United
            States and foreign countries.&nbsp; You may not distribute, modify,
            transmit, reuse, download, repost, copy or use Our Content, whether
            in whole or in part, outside the scope of the express license grants
            provided to you in these Terms.&nbsp;
          </p>

          <p className="graphText pb-10">
            We reserve all rights to Our Content not expressly granted in these
            Terms.&nbsp; You acknowledge and agree that the Company Platform is
            provided under license, and not sold, to you.&nbsp; You do not
            acquire any ownership interest in the Company Platform or Our
            Content under these Terms.&nbsp;
          </p>

          <h1 className="assessques thick-heading text-uppercase">Feedback</h1>

          <p className="graphText pb-10">
            We appreciate hearing from you and welcome your comments about the
            Company Platform, including whether you like it, what is most
            useful, any malfunctions, downtime, inappropriate content or
            complaints (collectively, &ldquo;
            <strong>
              <em>Feedback</em>
            </strong>
            &rdquo;).&nbsp; You can submit Feedback to us by emailing us at
            feedback@otishealth.com&nbsp;&nbsp;&nbsp;&nbsp; .&nbsp; Any Feedback
            you submit shall be Your Content.&nbsp;
          </p>

          <h1 className="assessques thick-heading text-uppercase">Term and Termination</h1>

          <p className="graphText pb-10">We reserve the right to modify, suspend or terminate the Company Platform at any time in our sole discretion.  Additionally, we may terminate, suspend or deactivate your Account or prohibit you from using or accessing the Company Platform for any reason, including non-payment, without notice, at any time and without liability to you.  If your Account is terminated or deactivated, all licenses and other rights granted to you in these Terms shall immediately cease and you will not be owed a refund of any products or services purchased prior to such termination or deactivation.</p>
          <p className="graphText pb-10">In the event your access to the Company Platform is terminated or operation of the Company Platform is discontinued as to all users, you will have three (3) months to download your personal records from the Platform.  After such period, the Company will not maintain your records, so it is important that you download such records when notified.  Notification of termination will be delivered to the user email address established for your account.</p>
          <p className="graphText pb-10">Similarly, you may cancel your account at any time, and such cancellation shall be effective three (3) months after notice of cancellation is received in order to give you time to download and retain patient records. However, the privacy and the warranty terms herein shall survive the cancellation. </p>


          <h1 className="assessques thick-heading text-uppercase">Updates and Support</h1>

          <p className="graphText pb-10">
            We may, from time to time and in our sole discretion, develop and
            provide updates to the Company Platform which may include upgrades,
            bug fixes, patches and other error corrections and/or new features
            (collectively, including related documentation, &ldquo;
            <strong>
              <em>Updates</em>
            </strong>
            &rdquo;).&nbsp; Updates may also modify or delete in their entirety
            current features and functionality.&nbsp; You agree that all Updates
            will be deemed part of the Company Platform and shall be subject to
            these Terms.
          </p>

          <p className="graphText pb-10">
            Notwithstanding the above paragraph, you acknowledge and agree that
            we have no obligation to: (a) provide you with technical support,
            (b) provide any Updates, or (c) continue to provide or enable any
            features or functionality of the Company Platform.
          </p>

          <p className="assessques thick-heading">PLATFORM IS NOT A MEDICAL DEVICE OR DIAGNOSTIC TOOL
          </p>

          <p className="graphText pb-10">
            The
            <strong>
              <em> Platform,</em>
            </strong>{" "}
            and any information obtained through it, is neither a medical device
            or diagnostic tool, nor is it intended to be medical advice.&nbsp;
            It does not take the place of medical and healthcare advice and
            services that you may receive from a physician or medical
            professional.&nbsp; The Platform is not intended, and must not be
            taken, to constitute the practice of medicine by OtisHealth or
            Eisai; therefore, use of the Platform does not create a
            doctor/patient or provider/patient relationship with OtisHealth or
            any third party. If you have a medical condition and have questions
            about your health records, diagnoses, and treatments, you must seek
            the advice of a qualified physician or medical professional.
          </p>



          <h1 className="assessques thick-heading text-uppercase">Warranties; Disclaimer of warranties</h1>

          <p className="graphText ptb-10">
            <em>Your Representations and Warranties</em>
          </p>

          <p className="graphText">
            You represent and warrant to us that: (a) all of the required
            Account information you submit is truthful and accurate, and you
            will maintain the accuracy of such information; (b) you have full
            power and authority to enter into these Terms; and (c) Your Content
            and your use of the Company Platform does not and shall not violate
            any laws or infringe or misappropriate any third party&rsquo;s
            intellectual property rights.
          </p>

          <p className="graphText ptb-10">
            <em>Our Disclaimer of Warranties to providers</em>
          </p>

          <p className="graphText">
            When using the <strong>Platform</strong> to view, read, request, download or share medical data, such as  <strong>electronic health records</strong>, we do not and cannot verify the completeness or accuracy of the information provided by external sources, including home health monitoring devices.  We cannot guarantee that the <strong>electronic health records</strong> format an external organization uses is, or can be, properly synchronized with, uploaded on, recorded to or represented on the <strong>Company Platform</strong>.</p>
          <p className="graphText ptb-10">
            <em>Our Disclaimer of Warranties to patients</em>
          </p>
          <p className="graphText pb-10">You may choose to use the&nbsp;<strong>Company Platform</strong>&nbsp;to
            share your health information with other users of the&nbsp;
            <strong>Company Platform</strong>&nbsp;and with external
            organizations such as medical service providers and clinical
            research organizations.&nbsp; Once you have shared this information,
            the&nbsp;<strong>Company</strong>&nbsp;has no control over what
            those other users and external organizations may do with your
            information.&nbsp; It is your responsibility to assess the risk of
            sharing your information with any other person or organization.
          </p>


          <p className="graphText">
            We do not guarantee or warrant any offers, invitations,
            recommendations or reviews that you view or receive while using the
            Company Platform. You understand that you should not rely solely on
            any advice or information received through the Company Platform to
            make any commercial, personal or other decisions.
          </p>
          <p className="graphText ptb-10">
            <em>Our Disclaimer Regarding Reimbursement and Claims to Providers</em>
          </p>
          <p className="graphText pb-10"><em>Rules Change</em> </p>

          <p className="graphText pb-10"> Reimbursement information does not constitute reimbursement or legal advice. OtisHealth gathers reimbursement information from various sources, but this information is subject to change without notice due to changing laws, regulations, rules, and policies.</p>

          <p className="graphText pb-10"> Payer policies can vary and should be verified before treatment to identify any limitations related to diagnosis, coding, or service site requirements. OtisHealth advises consulting with your payers, reimbursement specialists, or legal counsel on matters related to coding, coverage, and reimbursement.</p>

          <p className="graphText pb-10"><em> Providers Responsibilities</em></p>

          <p className="graphText pb-10"> Providers are encouraged to submit accurate and appropriate claims for services. It is each Provider’s responsibility to determine medical necessity, select the proper delivery site, and submit correct codes, charges, and modifiers for services provided. The coding options provided here are commonly used codes and are not an exhaustive list. Please consult relevant manuals to identify appropriate coding options.</p>

          <p className="graphText pb-10"> Providers must comply with Medicare national and local coverage determinations (NCDs and LCDs) and any other payer-specific coverage requirements, which may frequently change. Providers are solely responsible for choosing the service site and treatment methods that best meet the patient’s medical needs, based on the HCP's independent medical judgment.</p>
          <p className="graphText ptb-10"><em>Our Additional Disclaimer of Warranties to both Providers and Patients</em></p>
          <p className="graphText">
            You also understand that we cannot and do not guarantee or warrant:
            that the Company Platform, or any files, content or information
            available for download from the internet or through the Company
            Platform (including Third-Party Links), will be free of viruses or
            other harmful code. You alone are responsible for implementing
            sufficient procedures to satisfy your particular requirements for
            antivirus protection, accuracy of data and for maintaining a means
            external to the Company Platform for any reconstruction of any lost
            data.
          </p>

          <p className="graphText ptb-10">
            THE COMPANY PLATFORM AND OUR CONTENT ARE PROVIDED TO YOU ON AN
            &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT ANY
            WARRANTIES OF ANY KIND.&nbsp; WE EXPRESSLY DISCLAIM ANY AND ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
            THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT.&nbsp; WE DO NOT WARRANT
            THAT THE ADVICE PROVIDED THROUGH THE COMPANY PLATFORM WILL BE
            ACCURATE, HELPFUL, OR SOLVE ANY PARTICULAR PROBLEM, ISSUE OR
            CONCERN, OR THAT THE COMPANY PLATFORM WILL BE UNINTERRUPTED, TIMELY,
            SECURE, ERROR-FREE OR VIRUS-FREE, NOR DO WE MAKE ANY WARRANTY AS TO
            THE ADVICE OR OTHER CONTENT THAT IS PROVIDED OR MADE AVAILABLE
            THROUGH THE COMPANY PLATFORM.&nbsp;
          </p>

          <h1 className="assessques thick-heading text-uppercase">INDEMNIFICATION</h1>

          <p className="graphText pb-10">
            You agree to indemnify and hold us, Eisai Inc., each of our parents,
            subsidiaries and affiliates and each of their respective officers,
            directors, employees and agents harmless from any and all claims,
            demands, losses, liabilities, and expenses (including
            attorneys&rsquo; fees), arising out of or in connection with: (a)
            your use of the Company Platform and any other uses of the same
            through your Account; (b) your breach or violation of any of these
            Terms, including without limitation the restrictions on use provided
            herein, or any applicable laws or regulations; (c) our use of Your
            Content; or (d) your violation of the rights of any third
            party.&nbsp; We reserve the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us under these Terms, and you agree to
            cooperate with our defense of such claims.&nbsp; You agree not to
            settle any claims without our prior written consent.&nbsp; We will
            use reasonable efforts to notify you of any such claim, action or
            proceeding upon becoming aware of it.
          </p>

          <h1 className="assessques thick-heading">LIMITATION ON LIABILITY</h1>

          <p className="graphText  pb-10">
            WE SHALL NOT BE LIABLE TO YOU OR ANY OTHER PARTY FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION LOST PROFITS, LOST DATA, LOST PROPERTY, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE COMPANY PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  WE SHALL NOT BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL.  SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>

          <p className="graphText">
            IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU IN CONNECTION YOUR USE OF THE COMPANY PLATFORM FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED ONE HUNDRED U.S. DOLLARS (US $100).
          </p>

          <h1 className="assessques thick-heading ptb-10">INFORMAL DISPUTE RESOLUTION</h1>

          <p className="graphText">
            For any dispute between you and us, you agree to first contact us
            and attempt to resolve the dispute informally.&nbsp;
          </p>

          <h1 className="assessques thick-heading ptb-10">NOTICE</h1>

          <p className="graphText pb-10">
            You agree that we may provide notices to you through: (a) a
            prominent notice when you log in to your Account or a message sent
            to your Account; (b) an email sent to an address associated with
            your Account; or (c) through other means deemed sufficient by us,
            including without limitation telephone or mail.&nbsp; You agree to
            keep the contact information associated with your Account up to
            date.
          </p>

          <p className="graphText">
            You agree that the only way to provide legal notice to us is by
            email message sent to:
            notice@otishealth.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; .
          </p>

          <h1 className="assessques thick-heading ptb-10">GOVERNING LAW AND ARBITRATION</h1>

          <p className="graphText">
            Any dispute, claim or controversy arising from or relating to these
            Terms or your use of the Company Platform, including disputes
            arising from or concerning the interpretation, violation,
            invalidity, nonperformance or termination, shall be settled by
            arbitration administered under the Rules of Arbitration by the
            American Arbitration Association applying California law. The place
            of arbitration shall be San Francisco, California. Any such dispute,
            claim or controversy shall be arbitrated on an individual basis and
            shall not be consolidated in any arbitration with any dispute or
            claim of any other party. Arbitration shall be mandatory and binding
            on the parties. By using the Company Platform, you hereby waive all
            rights to trial in any action or proceeding instituted in connection
            with these Terms or the Company Platform.
          </p>

          <h1 className="assessques thick-heading ptb-10">GENERAL TERMS</h1>

          <p className="graphText">
            We reserve the right to change these Terms at any time.&nbsp;
            Amendments will be effective upon our providing notice of such
            updated Terms to you (a) by email sent to the address associated
            with your Account, or (b) by posting a prominent notice of such
            change on the Company Platform.&nbsp; Your continued access or use
            of the Company Platform after such notice constitutes your consent
            to be bound by the amended Terms.&nbsp; If any provision of these
            Terms is found to be unenforceable or invalid, that provision will
            be limited or eliminated to the minimum extent necessary so that
            these Terms will otherwise remain in full force and effect and be
            enforceable.&nbsp; These Terms, together with any commercial
            agreement you may enter into with us, are the complete and exclusive
            statement of the terms of use governing your use and access
            of the Company Platform, and they supersede and cancel all previous
            written and oral agreements, communications and other understandings
            relating to the subject matter of these Terms.&nbsp; No agency,
            partnership, joint venture or employment is created as a result of
            these Terms, and you do not have any authority of any kind to bind
            us in any respect whatsoever.&nbsp; Our failure to exercise or
            enforce any right or provision of these Terms shall not constitute a
            waiver of such right or provision.&nbsp; You may not assign these
            Terms without our prior written consent.&nbsp; We may assign or
            transfer these Terms, in whole or in part, without
            restriction.&nbsp; You agree that your Account is non-transferable
            and your rights to the content within your Account terminate upon
            your death.&nbsp;
          </p>

          <div className="d-flex align-items-center">
            <div className="form-check check-box" style={{ height: 35 }}>
              <input
                checked={checked}
                // onClick={onClick}
                onChange={handleCheckboxChange}
                className="form-check form-check-input"
                type="checkbox"
                id="gridCheck1"
              />
            </div>
            <p className="ml-3 thin-heading graphText dashColor">
              I agree to the{" "}
              <span className="textColor">Terms of Use</span>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
