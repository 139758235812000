import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import AppServices from "../../../services/appServices";
import { APIEndpoint } from "../../../config/apiendpoint";
import { notifyInfo, notifySuccess } from "../../../theme/Toaster/quickToasts";
import { selectStyle } from "../../../theme/theme";
import Select from "react-select";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface MyModalProps {
  showModal: boolean;
  handleClose: () => void;
  data?: any;
  handleSuccess?: () => void;
}

interface FormValues {
  selectedOption: any;
  message: string;
}

const SendMessageModal: React.FC<MyModalProps> = ({
  showModal,
  handleClose,
  data,
  handleSuccess,
}) => {
  const _appServices = new AppServices();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);


  const initialValues: FormValues = {
    selectedOption: "",
    message: "",
  };

  const showSuccessMessage = (message: string) => {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: message,
    });
  };

  const onSubmit = async (values: FormValues, { resetForm }: any) => {
    setLoading(true);

    let postJson = {
      message: values.message,
      to: values.selectedOption?.value,
    };
    try {
      const resp = await _appServices.postData(
        `${APIEndpoint.sendMessage}`,
        postJson
      );
      if (
        (resp.status === 201 || resp.status === 200) &&
        resp.success == true
      ) {
        setLoading(false);
        showSuccessMessage(resp.message);
        _appServices.logData({
          Severity: "Info",
          Activity: `User sent message to patient.`,
          ActivityStatus: "Success",
          ActivityResponse: "Data Submitted.",
          Operation: "User Activity",
        });
        if (handleSuccess) {
          handleSuccess();
        }
        handleClose();

      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: resp.message[0] || "Something went wrong.",
        });
        // console.log("error1", resp.message[0])
      }
    } catch (err: any) {
      console.log("error2", err)
      setLoading(false);
      // let captureErrorWord = ['sql','salt', 'cannot read properties of null']
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: (err && err?.message) ? captureErrorWord.some(i => err?.message.toLowerCase().includes(i)) ? "An error occurred while calling the service, please try after some time.": err?.message:"An error occurred while calling the service, please try after some time.",
      // });
      const message = _appServices.getErrorMessage(err)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message || "Something went wrong.",
      })
      _appServices.logData({
        Severity: "Error",
        Activity: `User failed to send message to patient.`,
        ActivityStatus: "Failure",
        ActivityResponse: "Data Submitted.",
        Operation: "User Activity",
      });
    }
    setLoading(false);
    resetForm();
    // handleClose();
  };

  const scriptTagsRegex = /(import|function|var|let|const|console.log\s*\(|console.error\s*\()/g;

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.selectedOption) {
      errors.selectedOption = "Required";
    }

    if (!values.message) {
      errors.message = "Required";
    } else if (scriptTagsRegex.test(values.message)) {
      errors.message = "Message cannot contain script tags or special characters.";
    }

    return errors;
  };
  useEffect(() => {
    // console.log("**data**",data)
    if (data && data.uuid) {
      setOptions([
        {
          value: data?.uuid,
          label: `${data?.firstName} ${data?.middleName} ${data?.lastName}`,
        }
      ])
    }
  }, [])

  return (
    <Modal
      className="modal modal-kyc send-message-popup"
      show={showModal}
      onHide={handleClose}
      animation={false}
    >
      {/* <Modal.Header
        className="header-popup"
        closeButton
        placeholder={null}
      ></Modal.Header> */}
      <div className="header-popup modal-header" >
        <button onClick={handleClose} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
      </div>
      <Modal.Body className="body-popup">
        <h6 className="thick-heading">Send an App Notification Message</h6>
        <p className="graphText mt-3 mb-4">
          Select the Patient to send a read-only Notification
          to their RPM mobile app.
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="selectedOption">
                <Form.Label>Send Message to:</Form.Label>
                <Field
                  as={Select}
                  className='capitalized'
                  name="selectedOption"
                  styles={selectStyle}
                  options={options}
                  onChange={(selectedOption: any) => {
                    setFieldValue("selectedOption", selectedOption);
                  }}
                  value={values.selectedOption}
                >
                  {/* <option value="" label="Select an option" />
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))} */}
                </Field>
                <ErrorMessage
                  name="selectedOption"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                {/* <Field
                  as="textarea"
                  name="message"
                  className="form-control custom-form-input-text-area"
                  rows={5}
                /> */}
                <CKEditor
                  editor={ClassicEditor as any}
                  data={values.message}
                  config={{
                    toolbar: {
                      items: [
                        'bold', 'italic', '|',
                        'bulletedList', 'numberedList', '|',
                        'undo', 'redo'
                      ]
                    }

                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("message", data);
                  }}
                  onReady={(editor) => {

                    if (editor && editor.editing && editor.editing.view && editor.editing.view.document) {
                      const root = editor.editing.view.document.getRoot();
                      if (root) {
                        editor.editing.view.change((writer) => {
                          writer.setStyle("height", "200px", root);
                        });
                      }
                    }
                  }}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>


              <Button
                className="primary-btn"
                type="submit"
                disabled={loading}
                style={{ display: "flex", alignItems: "center" }}
              >
                <i
                  className="pi pi-send mr-2"
                  style={{ transform: "rotate(43deg)" }}
                />
                <span>Send Message</span>
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SendMessageModal;
