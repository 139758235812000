import { PDFExport } from "@progress/kendo-react-pdf";
import React, { useRef } from 'react';
import { Modal } from "react-bootstrap";
import { LuDownload } from "react-icons/lu";
import alert_Icon from "../../../assets/image/alert_Icon.png";
import AppServices from "../../../services/appServices";
import { convertDate, getTime12hrsData, convertDateYYYYMMDD } from "../../utils/dateHelper";
import BloodGraphPdf from "../reportPdfModel/bloodGraphPdf";
import '../reportPdfModel/reportPdfModel.css';
import BloodGlucoseGraphPdf from "./BloodGlucoseGraphPdf";
import { TailSpin } from 'react-loader-spinner';
import moment from "moment";
declare var kendo: any;

interface ModalProps {
    showModal: boolean;
    handleClose: () => void;
    data: any;
    loading: any;
}

const ReportPdfModel: React.FC<ModalProps> = ({
    showModal,
    handleClose,
    data,
    loading
}) => {
    const dummyTableData = (data?.readingDatasPressure)?.map((entry: any) => ({
        // dateTime: `${convertDate(entry?.date)} ${getTime12hrsData(entry?.date)}`,
        dateTime: `${moment(entry?.date).format('MM/DD/YYYY hh:mm A')}`,
        device: entry?.device,
        range: `${entry?.range} ${entry?.unit}`,
        danger: entry?.danger
    }));
    const today = new Date();
    const pdfExportComponent = useRef<PDFExport>(null);
    // console.log(data)
    const handleDownload = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };
    const docRevDate = () => {
        return convertDateYYYYMMDD(today)
    }

    const HTMLPartToTextPart = (HTMLPart: string) => (
        HTMLPart
            .replace(/\n/ig, '')
            .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, '')
            .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, '')
            .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, '')
            .replace(/<\/\s*(?:p|div)>/ig, '\n')
            .replace(/<br[^>]*\/?>/ig, '\n')
            .replace(/<[^>]*>/ig, '')
            .replace(/&nbsp;/ig, ' ')
            .replace(/[^\S\r\n][^\S\r\n]+/ig, ' ')
            .replaceAll('&amp;', '&')
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('&quot;', '"')
            .replaceAll('&#39;', "'")
    );
    const replaceText = (template: any) => {
        return template.replace(/undefined/g, '');
    };
    const capitalizeFirstLetter = (str: any) => {
        if (!str) return str; // Return the original string if it is null or empty
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
    const getMessageContent = (message: any, index: any) => {
        const sanitizedMessage = HTMLPartToTextPart(message);
        return sanitizedMessage;
    }

    const formatDate = (dateString: any | null) => {
        if (!dateString) return '--';

        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '--';

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    
    // console.log("PDF Data", data)

    const PageTemplate = (props: any) => {
        return (
            <div>
                <div
                    className="justify-content-center w-100 pt-3 pb-1" id="assessment-content" style={{
                        position: 'absolute',
                        top: '15pt',
                        left: '10pt',
                    }} >
                    <div className="row mb-4" >
                        <div className="col-4">
                            <div className="ml-5">
                                <img
                                    style={{ width: '250px', height: '80px', paddingRight: '98px', alignItems: 'center' }}
                                    src={require("../../../assets/image/App_logo.png")}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <div
                                className="text-center pt-4 color-gray"
                                style={{ fontWeight: 600, fontFamily: 'Aptos, sans-serif' }}
                            >
                                CONFIDENTIAL - PATIENT HEALTH INFORMATION

                            </div>
                        </div>
                    </div>

                </div>
                <div className="pl-5 pb-4 "
                    style={{
                        position: 'absolute',
                        bottom: '10pt',
                        left: '10pt',

                    }}
                >
                    <div style={{ lineHeight: '16px', textAlign: 'left' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="color-gray bi bi-c-circle" viewBox="0 0 16 16" style={{ marginTop: '-0.5pt', color: '#666', fontFamily: 'Aptos, sans-serif' }}>
                            <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512" />
                        </svg> <span style={{ fontFamily: 'Aptos, sans-serif', color: '#666' }}  >Copyright 2024, OtisHealth Inc.</span>
                    </div>

                </div>
                <div className="pr-5 pb-4 color-gray"
                    style={{
                        position: 'absolute',
                        bottom: '10pt',
                        left: '55%',
                        transform: 'translateX(-50%)',
                        fontFamily: 'Aptos, sans-serif',
                        textAlign: 'center'
                    }}
                >
                    <span style={{ color: '#666', fontFamily: 'Aptos, sans-serif' }}>
                        RPM Report - DOCRev {docRevDate()}
                    </span>
                </div>

                <div className="pr-5 pb-4 color-gray"
                    style={{
                        position: 'absolute',
                        bottom: '10pt',
                        right: '10pt',
                        fontFamily: 'Aptos, sans-serif'
                    }}
                >
                    <span style={{ color: '#666', fontFamily: 'Aptos, sans-serif' }}>Page {props.pageNum} of {props.totalPages}</span>
                </div>

            </div>
        );
    };
    return (

        <Modal
            className="assessment-modal"
            show={showModal}
            onHide={handleClose}
            dialogClassName="modal-full-width"
        >
            <Modal.Title className="d-flex justify-content-center w-100 modalTitle p-0">
                <div className="d-flex justify-content-center w-100 modalTitle p-0"
                    style={{ marginRight: -20 }}>
                    <div
                        className="p-3"
                        onClick={handleDownload}
                        style={{ cursor: "pointer" }}
                    >
                        <span>
                            <LuDownload />
                        </span>
                        <span
                            className="big-p heading ml-2 mt-2"
                            style={{ cursor: "pointer" }}
                        >
                            Download
                        </span>
                    </div>
                </div>
                <div
                    className={"p-3 pr-5 ml-auto"}
                    onClick={handleClose}
                    style={{ cursor: "pointer", paddingRight: "20px" }}
                >
                    <span>X</span>
                </div>
            </Modal.Title>
            {loading ? <div className="d-flex align-items-center justify-content-center pt-5 pb-5">
                <TailSpin
                    visible={true}
                    height="40"
                    width="40"
                    color="#03A84E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                />
            </div> : <PDFExport pageTemplate={PageTemplate} ref={pdfExportComponent} paperSize='A4' margin='0.7cm' scale={0.5} fileName={`${Date.now()} - report.pdf`} forcePageBreak=".page-header" repeatHeaders={true} >

                <div className="mt-5 pl-5 pr-5 pb-5" >
                    <div
                        className="d-flex justify-content-center w-100 flex-column" id="assessment-content">
                        <div className="mt-5">
                            <div className="row pl-3 mt-1 mb-2">
                                <div className="col-12">
                                    <h5 className="font-bold">
                                        30-Day Cycle RPM Report </h5>
                                </div>
                            </div>
                            <hr className="mt-0" />
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">
                                        Patient</p>
                                </div>
                                <div className="col-8">
                                    <span className="capitalized">
                                        {[
                                            data?.patient?.firstName,
                                            data?.patient?.middleName,
                                            data?.patient?.lastName
                                        ]
                                            .filter(Boolean)
                                            .join(' ')}
                                    </span>
                                </div>

                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">30-Cycle Start Date</p>
                                </div>
                                <div className="col-8">
                                    <span>{formatDate(data?.cycleStartDate)} </span>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">30-Cycle End Date</p>
                                </div>
                                <div className="col-8">
                                    <span>{formatDate(data?.cycleEndDate)}</span>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Days of Readings</p>
                                </div>
                                <div className="col-8">
                                    <span>{data?.daysOfReadingCounts} </span>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Provider</p>
                                </div>
                                <div className="col-8">
                                    <span className="capitalized">
                                        {capitalizeFirstLetter([
                                            data?.provider?.firstName,
                                            data?.provider?.middleName,
                                            data?.provider?.lastName
                                        ]
                                            .filter(Boolean)
                                            .join(' '))}
                                    </span>
                                </div>
                            </div>
                            {data?.provider?.practiceName && <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Practice Name</p>
                                </div>
                                <div className="col-8">
                                    <span>{data?.provider?.practiceName}</span>
                                </div>
                            </div>}
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Measurement</p>
                                </div>
                                <div className="col-8">
                                    <span className="capitalized">{data?.measurement}</span>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Download Date</p>
                                </div>
                                <div className="col-8">
                                    <span>{formatDate(today)} </span>
                                </div>
                            </div>
                            <hr />
                            <div className="row pl-3">
                                <div className="col-12">
                                    <p className="font-bold">Device Training Completed  </p>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Date</p>
                                </div>
                                <div className="col-8">
                                    <span>{formatDate(data?.deviceTrainingCompleted?.date)}</span>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Staff </p>
                                </div>
                                <div className="col-8 capitalized">
                                    <span>
                                        {capitalizeFirstLetter([
                                            data?.deviceTrainingCompleted?.staff?.firstName,
                                            data?.deviceTrainingCompleted?.staff?.middleName,
                                            data?.deviceTrainingCompleted?.staff?.lastName
                                        ].filter(Boolean).join(' '))}
                                    </span>
                                </div>
                            </div>
                            {data?.deviceTrainingCompleted?.device && <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Device</p>
                                </div>
                                <div className="col-8">
                                    <span>{data?.deviceTrainingCompleted?.device}</span>
                                </div>
                            </div>}
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Measure</p>
                                </div>
                                <div className="col-8">
                                    <span>{data?.deviceTrainingCompleted?.measurementName}</span>
                                </div>
                            </div>

                            <br />
                            <div className="row pl-3">
                                <div className="col-12">
                                    <p className="font-bold">30-day Analysis Completed  </p>
                                </div>
                            </div>
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Date</p>
                                </div>
                                <div className="col-8">
                                    <span>{formatDate(data?.Completed30Days?.completedAt)} </span>
                                </div>
                            </div>
                            {data?.Completed30Days?.staff && <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Staff </p>
                                </div>
                                <div className="col-8">
                                    <span className="capitalized">
                                        {capitalizeFirstLetter([
                                            data?.Completed30Days?.staff?.firstName,
                                            data?.Completed30Days?.staff?.middleName,
                                            data?.Completed30Days?.staff?.lastName
                                        ].filter(Boolean).join(' '))}
                                    </span>
                                </div>
                            </div>}
                            <div className="row pl-3">
                                <div className="col-4">
                                    <p className="font-bold">Note </p>
                                </div>
                                <div className="col-8">
                                    <span>{data?.Completed30Days?.notes}</span>
                                </div>
                            </div>
                            <br />
                            {data?.treatmentPlans?.length > 0 && <div>
                                <div className="row pl-3" >
                                    <div className="col-12">
                                        <p className="font-bold mb-1">Treatment and Changes to Treatment Plan</p>
                                    </div>
                                </div>
                                {data?.treatmentPlans?.map((entry: any, index: any) => (
                                    <div className="row pl-3 mb-3" key={index}>
                                        <div className="col-12">
                                            <span>{formatDate(entry?.startDate)}: {entry?.logs?.map((log: any, index: any) => (
                                                <span key={index}>
                                                    {replaceText(log.log)}
                                                </span>
                                            ))} </span>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                            {data?.meassageHistory?.length > 0 && <div>
                                <div className="row pl-3">
                                    <div className="col-12">
                                        <p className="font-bold">Patient Communication  </p>
                                    </div>
                                </div>
                                {data?.meassageHistory?.map((entry: any, index: any) => (
                                    <div key={index}>
                                        <div className="row pl-3">
                                            <div className="col-2">
                                                <p className="font-bold mb-0">Sender </p>
                                            </div>
                                            <div className="col-8">
                                                <span className="capitalized">
                                                    {capitalizeFirstLetter([
                                                        entry?.sender?.firstName,
                                                        entry?.sender?.middleName,
                                                        entry?.sender?.lastName
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' '))}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row pl-3">
                                            <div className="col-2">
                                                <p className="font-bold mb-0">Date </p>
                                            </div>
                                            <div className="col-8">
                                                <span> {formatDate(entry?.date
                                                )}  </span>
                                            </div>
                                        </div>
                                        <div className="row pl-3">
                                            <div className="col-2">
                                                <p className="font-bold mb-0">Message </p>
                                            </div>
                                        </div>
                                        <div className="row pl-3 pb-3">
                                            <div className="col-12">
                                                <span>{getMessageContent(entry?.message, index)} </span>
                                            </div>
                                        </div>
                                        <hr className="mt-0" />
                                    </div>
                                ))}
                            </div>}
                            {/* <div className="page-header"></div> */}
                            {/* <div className="row pl-3" >
                                <div className="col-12">
                                    <p className="font-bold">Readings Collected  <span style={{ paddingLeft: '0pt' }}>(CPT Code <span style={{ paddingLeft: '4pt' }} className="font-bold">99454</span>)</span></p>
                                </div>
                            </div> */}

                            {data?.measurement == 'Blood Pressure' && <div className="row pl-3 page-header" >
                                <div className="col-12">
                                    <p className="font-bold">30-Day Graph </p>
                                    <div className='pt-2'>
                                    <p className='row'>Current cycle: <p className="font-bold">  {convertDate(data?.cycleStartDate)} - {convertDate(data?.cycleEndDate)}</p></p>
            <p className='row'>Days of readings within current cycle: <p className="font-bold"> {data?.daysOfReadingCounts} </p></p>

            </div>

                                </div>
                                <div className="col-12" >
                                    <div style={{ marginLeft: -60 }}>
                                        <BloodGraphPdf data={data?.readingDatasPressure?.reverse()} usePdf={true} />
                                    </div>
                                </div>
                            </div>}

                            {data?.measurement == 'Blood Pressure' && <div className="row pl-3">
                                <div className="col-12 mt-2 ">
                                    <p className="font-bold">30-Days of readings </p>
                                </div>
                                <div className="col-12 mb-3">
                                    {<div>
                                        {(dummyTableData?.length > 0) && <table className=" custom-adli-table table">
                                            <thead>
                                                <tr className="table th text-left custom-adli-table">
                                                    <th className="h custom-adli-table" style={{ fontSize: 16, fontWeight: 'bold' }}>Date</th>
                                                    <th className="custom-adli-table" style={{ fontSize: 16, fontWeight: 'bold' }}>Device</th>
                                                    <th className="custom-adli-table" style={{ fontSize: 16, fontWeight: 'bold' }}>Range</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dummyTableData?.map((entry: any, index: any) => (
                                                    <tr key={index} >
                                                        <td className="h custom-adli-table" style={{ fontSize: 16 }}>{entry?.dateTime}</td>
                                                        <td className="custom-adli-table" style={{ fontSize: 16 }}> {entry?.device}</td>
                                                        <td className="custom-adli-table" style={{ fontSize: 16 }}>{entry?.danger ? <img width={24} height={24} src={alert_Icon} /> : <span style={{ marginLeft: '22pt' }}>{' '}</span>} {entry.range}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>}
                                        {data?.readingDatasPressure?.length == 0 && <p>
                                            No Data Found
                                        </p>}
                                    </div>}
                                </div>
                                <br />
                            </div>}

                            {data?.measurement == 'Blood Glucose Level' && <div className="row pl-3 page-header">
                                <div className="col-12">
                                    <p className="font-bold">30-Day Graph </p>
                                    <div className='pt-2'>
                                    <p className='row'>Current cycle: <p className="font-bold">{convertDate(data?.cycleStartDate)} - {convertDate(data?.cycleEndDate)}</p></p>
            <p className='row'>Days of readings within current cycle: <p className="font-bold">{data?.daysOfReadingCounts}</p></p>
            </div>

                                </div>
                                <div className="col-12" >
                                    <div style={{ marginLeft: -16 }}>
                                        <BloodGlucoseGraphPdf data={data?.readingDatasGlucose?.reverse()} />
                                    </div>
                                </div>
                            </div>}
                            <div className="row pl-3">

                                <div className="col-12">
                                    <p className="font-bold italic">Suggested CPT Codes </p>
                                </div>
                                <div className="col-12 mb-1">
                                    <span>Device Training Completed:  CPT Code 99453.  </span>
                                </div>
                                <div className="col-12 mb-1">
                                    <span>30-day Analysis Completed:  CPT Code 99091.  </span>
                                </div>
                                <div className="col-12 mb-1">
                                    <span>Patient Communication:  CPT Code 99457 for initial communication;  CPT Code 99458 for additional communications.  </span>
                                </div>
                                <div className="col-12 mb-1">
                                    <span>Readings Collected:  CPT Code 99454   </span>
                                </div>

                                <div className="col-12 mt-3 ">
                                    <p className="font-bold italic">CPT Code Resources </p>
                                </div>
                                <div className="col-12">
                                    <span>For information on the CPT code descriptions and latest reimbursement rules, please visit the CMS website.</span><br />
                                    <a href="https://www.cms.gov/medicare/regulations-guidance/physician-self-referral/list-cpt-hcpcs-codes">List of CPT/HCPCS Codes | CMS</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </PDFExport>}
        </Modal >
    );
};

export default ReportPdfModel;
