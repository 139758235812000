import React, { useRef, useEffect } from 'react';
import {
    CartesianGrid,
    Dot,
    Legend,
    Line,
    ReferenceLine,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { convertDateFormat, getDDDMMDD, getTime12hrsData,convertDate } from '../../utils/dateHelper';
import './styles.css';
import moment from 'moment';
import { Blocks, TailSpin } from 'react-loader-spinner';

const BloodGlucoseGraph = ({ data, isMonitoringAssigned,loading,readingCycle }) => {
    const bloodGlucoseGraphRef = useRef(null);
    useEffect(() => {
        if (bloodGlucoseGraphRef.current) {
            bloodGlucoseGraphRef.current.scrollLeft = bloodGlucoseGraphRef.current.scrollWidth;
        }
    }, [data]);

    const CustomDot = ({ cx, cy, fill, payload }) => {
        let dotColor = fill;
        dotColor = payload.isAbnormalGlucoseReading ? 'red' : '#E2B71F';
        return (
            <circle
                cx={cx}
                cy={cy}
                r={4}
                fill={dotColor}
                stroke="none"
                style={{ pointerEvents: 'none' }}
            />
        );
    };

    const calculateBarchartWidth = (numberOfItem = 7) => {
        if (numberOfItem > 6) return numberOfItem * 75;
        return '100%';
    };

    const formattedData = (data)?.map(entry => ({
        date: getDDDMMDD(entry.date),
        bpm: parseInt(entry.glucoseReading || 0),
        isAbnormalGlucoseReading: entry?.isAbnormalGlucoseReading,
        bloodGlucoseLevelMin: entry.bloodGlucoseLevelMin,
        bloodGlucoseLevelMax: entry.bloodGlucoseLevelMax
    })) || [];
    // console.log("blood glucose data", data)

    const bloodGlucoseMin = Math.min(...formattedData.map(entry => entry.bloodGlucoseLevelMin));
    const bloodGlucoseMax = Math.min(...formattedData.map(entry => entry.bloodGlucoseLevelMax));

    const tableData = (data)?.map(entry => ({
        dateTime: `${moment(entry?.date).format('MM/DD/YYYY hh:mm A')}`,
        device: entry?.device,
        bpm: `${entry.glucoseReading} ${entry.unit}`,
        isAbnormalGlucoseReading: entry?.isAbnormalGlucoseReading
    })) || [];

    if (!isMonitoringAssigned) {
        return null;
    }

    const CustomLegend = ({ data }) => {
        return (
            <div style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20 }}>
                {data.map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                        <div
                            style={{
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                backgroundColor: item.color,
                                marginRight: 10,
                            }}
                        ></div>
                        <span style={{ color: '#000', fontSize: 14 }}>{item.name}</span>
                    </div>
                ))}
            </div>
        );
    };
    const legendData = [
        { name: 'mg/dL', color: '#E2B71F' },
    ];


    return (
        <div className="graph-container">
            <h2 className='graphHeading'>Blood Glucose</h2>
            <div className='pt-2'>
            <p className='graphCycle'>Current cycle: {convertDate(readingCycle?.cycleStart)} - {convertDate(readingCycle?.cycleEnd)}</p>
            <p className='graphCycle'>Days of readings within current cycle: {readingCycle?.readingCount}</p>
            </div>

            {loading && 
             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: '50px'}}>
             <TailSpin
               visible={true}
               height="80"
               width="80"
               color="#03A84E"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
             />
               </div>
            }
            {(data && data.length && !loading) ? (
                <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomLegend data={legendData} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '65px' }}>
                            <ResponsiveContainer width={'100%'} height={275}>
                                <LineChart data={formattedData}>
                                    <YAxis
                                        // dy={20}
                                        type="number"
                                        dataKey='bpm'
                                        axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                                        tick={{ fill: '#000', fontSize: 12 }}
                                        padding={{ right: 20 }}
                                        tickLine={false}
                                        style={{ fontFamily: 'Open Sans, sans-serif' }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }} ref={bloodGlucoseGraphRef}>
                            <ResponsiveContainer width={calculateBarchartWidth(formattedData.length)} height={300}>
                                <LineChart data={formattedData?.reverse()}>
                                    <XAxis
                                        dataKey="date"
                                        angle={0}
                                        dx={-30}
                                        minTickGap={-200}
                                        textAnchor="start"
                                        tick={{ fill: '#000', fontSize: 12 }}
                                        tickLine={false}
                                        axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                                        padding={{left:30, right: 20 }}
                                        style={{ fontFamily: 'Open Sans, sans-serif' }}
                                    />
                                    <Tooltip />
                                    {/* <Legend
                                    verticalAlign="top"
                                    align="right"
                                    iconType="circle"
                                    iconSize={26}
                                    wrapperStyle={{ paddingLeft: 20, paddingBottom: 50 }}
                                    formatter={(value, entry) => (
                                        <span style={{ color: '#000', fontSize: 14 }}>
                                            {value}
                                        </span>
                                    )}
                                /> */}
                                    <Line
                                        type="monotone"
                                        dataKey="bpm"
                                        stroke="#E2B71F"
                                        name="mg/dL"
                                        strokeWidth={3}
                                        // dot={(props) => <CustomDot {...props} />}
                                        dot={(props) => {
                                            const { key, ...rest } = props;
                                            return <CustomDot {...rest} />;
                                        }}
                                    />
                                    {/* <ReferenceLine y={bloodGlucoseMin} stroke="black" strokeDasharray="3 3" />
                                    <ReferenceLine y={bloodGlucoseMax} stroke="black" strokeDasharray="3 3" /> */}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </>
            ) : (
                <div>
          {
            !loading && <p className='d-flex justify-content-center align-items-center' style={{ minHeight: 300 }}>
             No Chart Data Found
           </p>
          }

        </div>
            )}

            {<div className='graphtable-container'>
                <table className='graphdata-table'>
                    <thead className='graphTableHead'>
                        <tr className='graphTableborder'>
                            <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Date & Time</th>
                            <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Device</th>
                            <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Range</th>
                        </tr>
                    </thead>
                </table>
                <div className="table-body-container">
                    <table className="graphdata-table">
                        <tbody>
                            {tableData.map((entry, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.dateTime}</td>
                                    <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.device}</td>
                                    <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.bpm}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {tableData.length === 0 && !loading && (
                    <p className='d-flex justify-content-center align-items-center'>No Data Found</p>
                )}
            </div>}
        </div>
    );
};

export default BloodGlucoseGraph;
