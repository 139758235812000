import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import careGiver from "../../../../assets/image/careGiver.png";
import addPatient from "../../../../assets/image/addPatient.png";

import { Field, FieldArray, Formik } from "formik";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import {
  top_header_button,
  btn_primary,
  selectStyle,
  selectStyleMobile,
} from "../../../../theme/theme";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { UserContext } from "../../../../context/UserContext";
import AppServices from "../../../../services/appServices";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "../../../../theme/Toaster/quickToasts";
import { APIEndpoint } from "../../../../config/apiendpoint";
import { useLocation } from "react-router-dom";
import { manageSchema, manageSchema2 } from "../validationData";
import Swal from "sweetalert2";
import { convertDate, convertDateFormat1 } from "../../../utils/dateHelper";
import InputMask from 'react-input-mask';

export default function ManagePatientsInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  const [patientData, setPatientData] = useState<any>(undefined);

  const { genderInfo, relationshipInfo, countryCode } = useContext(UserContext);
  const _appServices = new AppServices();

  const [gender, setGender] = useState([]);
  const [relationship, setRelationship] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState<any[]>([]);


  const [patient, setPatient] = useState<any>();
  const [schemaN, setSchemaN] = useState<any>(manageSchema);

  useEffect(() => {
    if (genderInfo && genderInfo.length) {
      let genderDatas: any = genderInfo.map((d: any) => ({
        value: d.id,
        label: d.name,
      }));
      setGender(genderDatas);
    }
    if (relationshipInfo && relationshipInfo.length) {
      let relationshipDatas: any = relationshipInfo.map((d: any) => ({
        value: d.id,
        label: d.name,
      }));
      setRelationship(relationshipDatas);
    }
    if (countryCode && countryCode.length) {
      let countries: any = countryCode.map((d: any) => ({
        value: d.countryCode,
        label: d.countryCode,
      }));
      setCountryCodeList(countries);
    }
  }, [genderInfo, relationshipInfo, countryCode]);

  useEffect(() => {
    setPatientData(location.state?.patientUid);
  }, [location.state?.patientUid]);

  const fetchData = async () => {
    try {
      if (patientData) {
        const res = await _appServices.getData(
          `${APIEndpoint.getPatientByUid}${patientData}`
        );
        if (res.status === 200 && res.success) {
          setPatient(res.data[0]);
        } else {
          notifyInfo(res.message[0]);
        }
      }
    } catch (err: any) {
      notifyError(err.message[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [patientData]);

  const handlePatientProfile = () => {
    navigate(`/user-panel/patientProfile/${patient?.uuid}`,{state:{...patient,uuid:patient?.uuid}});
  };

  const handleEnrollPatient = async (values: any, resetForm: any, setSubmitting:any) => {
    setSubmitting(true);
    const patientData = {
      patientUid: patient?.uuid,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.mobileNumber,
      gender: parseInt(values.gender),
      dob: convertDateFormat1(values.dob),
      isPatientManage: false
    };


   

    try {
      const res = await _appServices.postData(
        `${APIEndpoint.upsertPatientCaregiver}`,
        patientData
      );

      if (res.status === 200 && res.success) {
        // notifySuccess(res.message);
     
        // _appServices.showFlashMsg('success', 'Success', res.message, false);
        // resetForm();
        setPatient({...patientData,uuid:patient?.uuid})
        setSubmitting(false)
        Swal.fire({
          title: "Success!",
          text: res.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            
            navigate(`/user-panel/patientProfile/${patient?.uuid}`,{state:{...patientData,uuid:patient?.uuid}});
            resetForm();
            setSubmitting(false); 
          }
        });
        
        _appServices.logData({
          Severity: 'Info',
          Activity: `User updated patient in manage patient profile.`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
      } else {
        // notifyInfo(res.message[0]);
        // navigate("/user-panel/invitation");
        const message = _appServices.getErrorMessage(res)
        _appServices.showFlashMsg('info', 'Success', message, false);
        setSubmitting(false)
      }
    } catch (err: any) {
      setSubmitting(false)
      // notifyError(err.message[0]);
      const message = _appServices.getErrorMessage(err)
      // let captureErrorWord = ['sql','salt', 'cannot read properties of null']
      Swal.fire({
        icon: "error",
        title: "Oops...",
        // text: (err && err?.message) ? captureErrorWord.some(i => err?.message.toLowerCase().includes(i)) ? "An error occurred while calling the service, please try after some time.": err?.message:"An error occurred while calling the service, please try after some time.",
        text: message || "Something went wrong.",
      })
      // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
      _appServices.logData({
        Severity: 'Error',
        Activity: `User failed to enroll new patient in manage profile.`,
        ActivityStatus: 'Failed',
        ActivityResponse: err,
        Operation: 'User Activity',
      })
    }
    setSubmitting(false)
  };


const schema = schemaN;

const checkDOB=(d:any)=>{
  return typeof d== "string" ? convertDate(d) : new Date(d)
}


const getCountryCode = (phone:any) => {
  if (phone === null) {
    return ;
  }
  const countryCodeMatch = phone.match(/\(\+(\d+)\)/);
  const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';

  return countryCode;
};

const getPhoneNumber = (phone:any) => {
  if (phone === null) {
    return '';
  }
  const countryCodeMatch = phone.match(/\(\+(\d+)\)/);
  const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';
  const formattedPhoneNumber = phone.replace(countryCode, '');

  return formattedPhoneNumber;
};
  return (
    <div className="container top-margin-container">
      <div className="text-left">
        <h6
          className="h6 heading textColor ml-0"
          onClick={handlePatientProfile}
          style={{cursor:'pointer'}}
        >
          &lt; Back to Patient Profile
        </h6>
        <div className="mb-5 ml-3">
          <h5 className="h5 heading mt-4">
            Manage Patient Information
          </h5>

          <div className="text-left mt-5 mb-4">
            {patient ? (
              <Formik
                validationSchema={schema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  handleEnrollPatient(values, resetForm, setSubmitting);
                }}
                initialValues={{
                  firstName: patient.firstName || "",
                  middleName: patient.middleName || "",
                  lastName: patient.lastName || "",
                  dob: patient.dob || "",
                  gender: parseInt(patient.gender) || "",
                  // countryCode: getCountryCode(patient?.phoneNumber) || "(+1)",
                  mobileNumber: getPhoneNumber(patient.phoneNumber) || "",
                  email: patient.email || "",
                  isPatientManage: false
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  touched,
                  isSubmitting
                }: any) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <div className="text-left mt-2 d-flex flex-row">
                        <img
                          src={addPatient}
                          style={{ height: "32px", width: "26px",color:'red' }}
                          alt=""
                        />
                        <h6 className="h6 heading ml-3 mr-5">
                          Patient Information 
                        </h6>

                      </div>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik01"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          //placeholder="First Name"
                          type="text"
                          name="firstName"
                          value={values.firstName.trim("")}
                          onChange={handleChange}
                          // isValid={touched.firstName && !errors.firstName}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik02"
                      >
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          //placeholder="Middle Name"
                          type="text"
                          name="middleName"
                          value={values.middleName.trim("")}
                          onChange={handleChange}
                          // isValid={touched.middleName && !errors.middleName}
                          isInvalid={!!errors.middleName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.middleName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik03"
                      >
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          type="text"
                          //placeholder="Last Name"
                          name="lastName"
                          value={values.lastName.trim("")}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik04"
                      >
                        <Form.Label>Date of Birth *</Form.Label>
                        <DatePicker
                          className="react-date-picker custom-form-input-date"
                          name="dob"
                          value={values.dob ? checkDOB(values.dob): ""}
                          format="MM/dd/yyyy"
                          onChange={(date: any) => {
                            if (date) {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: date,
                                },
                              });
                            } else {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: "",
                                },
                              });
                            }
                          }}
                          maxDate={new Date()}
                        />
                        {errors.dob && (
                          <div className="errorText">{errors.dob}</div>
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik05"
                      >
                        <Form.Label>Gender*</Form.Label>

                        {gender.length > 0 ? (
                          <Field
                            as={Select}
                            name="gender"
                            options={gender}
                            onChange={(selectedOption: { value: any }) => {
                              handleChange({
                                target: {
                                  name: "gender",
                                  value: selectedOption.value,
                                },
                              });
                            }}
                            styles={selectStyle}
                            value={gender.find(
                              (option: any) => option.value == values.gender
                            )}
                            placeholder="Choose One"
                            isSearchable
                          />
                        ) : (
                          <p>Loading...</p>
                        )}
                        {errors.gender && (
                          <div className="errorText">{errors.gender}</div>
                        )}
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik06"
                      >
                        <Form.Label>Mobile Phone Number *</Form.Label>
                        
                        <InputMask
                          mask="+1 (999) 999-9999"
                          placeholder="+1 (999) 999-9999"
                          // maskChar={null}
                          value={values.mobileNumber.trim("")}
                          onChange={(e) => {
                            const maskedValue = e.target.value;
                            const numericValue = maskedValue.replace(/[^\d]/g, '');
                            const trimmedValue = numericValue.substring(1); 
                            handleChange({
                              target: {
                                name: 'mobileNumber',
                                value: trimmedValue
                              }
                            });
                          }}
                          className="custom-form-input"
                          type="text"
                          name="mobileNumber"
                        />
                          {errors.mobileNumber && (
                            <div className="errorText">{errors.mobileNumber}</div>
                          )}

                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik07"
                      >
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          //placeholder="Email"
                          type="text"
                          name="email"
                          value={values.email.trim("")}
                          onChange={handleChange}
                          // isValid={touched.email && !errors.email}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Button
                      type="submit"
                      className="primary-btn"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <div>
                <h3>...Loading</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
